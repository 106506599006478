import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./MenuItem.css";

function MenuItem ({ className, children }) {
  const cls = cx("l6r__menu-item", {
    [className]: className,
  });

  return (
    <div className={cls}>
      {children}
    </div>
  );
}

MenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default MenuItem;

import BasePlatform from "./BasePlatform";
import windowMessageObserver from "../observers/WindowMessageObserver";
import Command from "../commands/Command";

export default function Egt () {
  (() => {
    const handleWindowMessage = data => {
      const { command } = data;

      if (command === "com.egt-bg.exit") {
        Command.openLobby();
      }
    };

    windowMessageObserver.subscribe("egt", handleWindowMessage);
  })();

  return new BasePlatform();
}

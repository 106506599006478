import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./DialogHeader.css";

function DialogHeader ({ title, bordered, icon: Icon }) {
  if (!title && !Icon) {
    return null;
  }

  const cls = cx("l6r__dialog-header", {
    "l6r__dialog-header--bordered": bordered,
  });

  return (
    <div className={cls}>
      {Icon && (
        <div className="l6r__dialog-icon">
          <Icon />
        </div>
      )}

      {title && (
        <div className="l6r__dialog-title">
          {title}
        </div>
      )}
    </div>
  );
}

DialogHeader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.func,
  bordered: PropTypes.any,
};

export default DialogHeader;

import React from "react";
import "./SticksSpinner.css";

function SticksSpinner () {
  const sticks = Array.from({ length: 8 }, (_, i) => ({ key: i }))

  return (
    <div className="l6r__sticks-spinner">
      {sticks.map(item => (
        <div
          key={`stick-${item.key}`}
          className="l6r__sticks-spinner__stick"
        />
      ))}
    </div>
  );
}

export default SticksSpinner;

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./DialogBody.css";

function DialogBody ({ children, opened, padded }) {
  const cls = cx("l6r__dialog-body", {
    "l6r__dialog-body--opened": opened,
    "l6r__dialog-body--padded": padded,
  });

  return (
    <div className={cls}>
      {children}
    </div>
  );
}

DialogBody.propTypes = {
  children: PropTypes.node,
  padded: PropTypes.any,
  opened: PropTypes.any,
};

export default DialogBody;

const STATUS_ACTIVE = 1;
const STATUS_INACTIVE = 0;

function BaseObserver () {
  this.whiteList = [];
  this.handlers = {};
  this.status = STATUS_INACTIVE;

  this.activate = function () {
    this.status = STATUS_ACTIVE;
  };

  this.disable = function () {
    this.status = STATUS_INACTIVE;
  };

  this.isActive = function () {
    return this.status === STATUS_ACTIVE;
  };

  this.setWhiteList = function (ids) {
    this.whiteList = ids;
  };

  this.isAllowed = function (id) {
    return this.whiteList.includes(id);
  };

  this.addHandler = function (id, handler) {
    this.handlers[`${id}`] = handler;
  };

  this.removeHandler = function (id) {
    delete this.handlers[`${id}`];
  };

  this.isEmpty = function () {
    return Object.values(this.handlers).length === 0;
  };

  this.execute = function (...attr) {
    for (const handler of Object.values(this.handlers)) {
      handler(...attr);
    }
  };
}

export default BaseObserver;

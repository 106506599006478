import BasePlatform from "../platforms/BasePlatform";
import EgtPlatform from "../platforms/Egt";
import EgtDigitalPlatform from "../platforms/EgtDigital";
import RelaxPlatform from "../platforms/Relax";
import GreentubePlatform from "../platforms/Greentube";
import MascotPlatform from "../platforms/Mascot";
import MerkurPlatform from "../platforms/Merkur";
import PlayngoPlatform from "../platforms/Playngo";
import PlaytechPlatform from "../platforms/Playtech";
import QuickspinPlatform from "../platforms/Quickspin";
import TruelabPlatform from "../platforms/Truelab";
import WazdanPlatform from "../platforms/Wazdan";
import BetgamestvPlatform from "../platforms/Betgamestv"
import SpribePlatform from "../platforms/Spribe";

export default function initPlatform (platformName) {
  return new Promise((resolve, reject) => {
    let platform = null;

    switch (platformName) {
      case "Egtdigital":
        platform = new EgtDigitalPlatform();
        break;
      case "Egt":
        platform = new EgtPlatform();
        break;
      case "Relax":
      case "Relaxm":
        platform = new RelaxPlatform();
        break;
      case "Greentube":
        platform = new GreentubePlatform();
        break;
      case "Mascot":
        platform = new MascotPlatform();
        break;
      case "Merkur":
        platform = new MerkurPlatform();
        break;
      case "Playngo":
        platform = new PlayngoPlatform();
        break;
      case "Playtech":
        platform = new PlaytechPlatform();
        break;
      case "Quickspin":
        platform = new QuickspinPlatform();
        break;
      case "Truelab":
        platform = new TruelabPlatform();
        break;
      case "Wazdan":
        platform = new WazdanPlatform();
        break;
      case "Betgamestv":
        platform = new BetgamestvPlatform();
        break;
      case "Spribe":
        platform = new SpribePlatform();
        break;
      default:
        platform = new BasePlatform();
    }

    return resolve(platform);
  });
}

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Link.css";

function Link ({
  className,
  iconClassName,
  href,
  text,
  title,
  icon: Icon,
  onClick = Function.prototype,
  clickDelay,
  color,
  fill,
  small,
  disabled,
  primary,
  secondary,
  addonAfter,
  target = "_self",
}) {
  const timeoutRef = useRef();
  const [ animation, setAnimation ] = useState(0);

  const handleClick = e => {
    e.preventDefault();

    if (clickDelay) {
      return null;
    }

    onClick(e);
  };

  const handleMouseDown = e => {
    if (!clickDelay) {
      return null;
    }

    setAnimation(1);

    timeoutRef.current = setTimeout(() => {
      onClick(e);
      timeoutRef.current = null;
      setAnimation(2);
    }, clickDelay);
  };

  const handleMouseUp = () => {
    if (animation !== 2) {
      setAnimation(0);
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const getLinkProps = () => {
    const common = {
      className: cls,
      target,
      title,
    };

    if (href) {
      return { ...common, href };
    }

    return {
      ...common,
      onClick: handleClick,
      onMouseDown: handleMouseDown,
      onMouseUp: handleMouseUp,
      onTouchStart: handleMouseDown,
      onTouchEnd: handleMouseUp,
      onTouchCancel: handleMouseUp,
    };
  };

  const cls = cx("l6r__link", {
    "l6r__link--icon-only": !text,
    "l6r__link--fill": fill,
    "l6r__link--small": small,
    "l6r__link--primary": primary,
    "l6r__link--secondary": secondary,
    "l6r__link--disabled": disabled,
    "l6r__link--progress": clickDelay,
    "l6r__link--progress-active": animation === 1,
    "l6r__link--progress-finish": animation === 2,
    [`l6r__link--${color}`]: color,
    [className]: className,
  });

  const clsIcon = cx("l6r__link-icon", {
    [iconClassName]: iconClassName,
  });

  return (
    <a {...getLinkProps()}>
      {Icon && (
        <span className={clsIcon}>
          <Icon />
        </span>
      )}

      <span className="l6r__link-text">
        {text}
      </span>

      {addonAfter}
    </a>
  );
}

Link.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  clickDelay: PropTypes.number,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  color: PropTypes.oneOf(["red", "green"]),
  fill: PropTypes.any,
  small: PropTypes.any,
  disabled: PropTypes.any,
  primary: PropTypes.any,
  secondary: PropTypes.any,
  target: PropTypes.oneOf(["_self", "_blank"]),
  addonAfter: PropTypes.node,
};

export default Link;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import DialogBody from "./DialogBody";
import DialogHeader from "./DialogHeader";
import DialogContent from "./DialogContent";
import DialogFooter from "./DialogFooter";
import "./Dialog.css";

function Dialog ({
  icon,
  title,
  text,
  confirmLabel,
  cancelLabel,
  onConfirmClick,
  onCancelClick,
  buttons,
  toast,
  modal,
  padded = true,
  className,
  children,
  pause = 0,
}) {
  const [ opened, setOpened ] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => { setOpened(true); }, pause * 500);

    return () => {
      clearTimeout(timer);
    }
  }, [pause]);

  const cls = cx("l6r__dialog", {
    "l6r__dialog--toast": toast,
    "l6r__dialog--modal": modal,
    [className]: className,
  });

  return (
    <div className={cls}>
      {children || (
        <DialogBody opened={opened} padded={padded}>
          <DialogHeader
            icon={icon}
            title={title}
          />

          <DialogContent text={text} opened={opened} />

          <DialogFooter
            buttons={buttons}
            confirmLabel={confirmLabel}
            cancelLabel={cancelLabel}
            onConfirmClick={onConfirmClick}
            onCancelClick={onCancelClick}
          />
        </DialogBody>
      )}
    </div>
  );
}

Dialog.Body = DialogBody;
Dialog.Header = DialogHeader;
Dialog.Content = DialogContent;
Dialog.Footer = DialogFooter;

Dialog.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  buttons: PropTypes.array,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  pause: PropTypes.number,
  toast: PropTypes.any,
  modal: PropTypes.any,
  padded: PropTypes.any,
  children: PropTypes.node,
};

export default Dialog;

const FACTORIES = {
  "text/javascript": scriptFactory,
  "text/css": cssLinkFactory,
};

export default function injectScript ({
  type = "text/javascript",
  ...rest
}) {
  const factory = FACTORIES[type];

  document.head.appendChild(factory(rest));
}

function scriptFactory ({ src, onload }) {
  const element = document.createElement("script");

  element.type = "text/javascript";
  element.src = src;
  element.onload = onload;

  return element;
}

function cssLinkFactory ({ src: href, rel, onload }) {
  const element = document.createElement("link");

  element.type = "text/css";
  element.href = href;
  element.rel = rel;
  element.onload = onload;

  return element;
}

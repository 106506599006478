import Validator from "./Validator";
import { PROJECT_NAME_KEY } from "../constants";

export default function GameOptionsValidator () {
  this.validate = function (gameOptions) {
    this.validateProjectName(gameOptions[`${PROJECT_NAME_KEY}`]);

    return {
      isValid: this.isValid(),
      error: this.getError(),
    };
  };
}

GameOptionsValidator.prototype = new Validator();

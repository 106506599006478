import BaseObserver from "./BaseObserver";

const MESSAGE_EVENT = "message";
const WHITE_LIST = Object.freeze([
  "base",
  "betgamestv",
  "betsoft",
  "egt",
  "greentube",
  "merkur",
  "playngo",
  "truelab",
  "wazdan",
  "recentGames",
]);

function WindowMessageObserver () {
  const base = new BaseObserver();
  base.setWhiteList(WHITE_LIST);

  function subscribe (id, handler) {
    if (!base.isAllowed(id)) {
      return;
    }

    base.addHandler(id, handler);

    if (!base.isActive()) {
      window.addEventListener(MESSAGE_EVENT, handleMessage, false);
      base.activate();
    }
  }

  function unsubscribe (id) {
    if (!base.isAllowed(id)) {
      return;
    }

    base.removeHandler(id);

    if (base.isEmpty()) {
      window.removeEventListener(MESSAGE_EVENT, handleMessage);
      base.disable();
    }
  }

  function handleMessage (e) {
    if (!e.data) {
      return;
    }

    base.execute(e.data);
  }

  return Object.freeze({
    subscribe,
    unsubscribe,
  });
}

const windowMessageObserver = new WindowMessageObserver();

export default windowMessageObserver;

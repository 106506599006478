function GTMNotifier () {
  function push ({ event, gameId, gameName, action, project }) {
    const dataLayer = window.dataLayer || [];

    dataLayer.push({
      id: gameId,
      name: gameName,
      event,
      action,
      project,
    });
  }

  function addToFavorites ({ gameId, gameName, project }) {
    push({
      event: "add_game_to_favorite",
      action: "add",
      gameId,
      gameName,
      project,
    });
  }

  function removeFromFavorites ({ gameId, gameName, project }) {
    push({
      event: "add_game_to_favorite",
      action: "remove",
      gameId,
      gameName,
      project,
    });
  }

  return ({
    addToFavorites,
    removeFromFavorites,
  });
}

const gtmNotifier = new GTMNotifier();

export default gtmNotifier;

import React from "react";
import "./Spinner.css";

function Spinner () {
  const cls = "l6r__spinner";

  return (
    <div className={cls} />
  );
}

export default Spinner;

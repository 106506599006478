import windowMessageObserver from "../observers/WindowMessageObserver";
import Command from "../commands/Command";
import { IFRAME_ID } from "../constants";

export default function Merkur () {
  let gameOptions = null;

  function setGameOptions (options) {
    gameOptions = options;
  }

  function run () {
    document.getElementById(IFRAME_ID).src = gameOptions.getGameUrl();

    windowMessageObserver.subscribe("merkur", handleWindowMessage);
  }

  function handleWindowMessage (data) {
    try {
      const messageData = JSON.parse(data);

      if (!messageData) {
        return;
      }

      if (messageData.name === "notifyCloseContainer") {
        Command.openLobby();
      }
    } catch {}
  }

  return Object.freeze({
    setGameOptions,
    run,
  });
}

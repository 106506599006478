import windowMessageObserver from "../observers/WindowMessageObserver";
import { IFRAME_ID } from "../constants";

export default function Greentube () {
  let gameOptions = null;
  let freeSpinsFinished = false;

  function setGameOptions (options) {
    gameOptions = options;
  }

  function run () {
    document.getElementById(IFRAME_ID).src = gameOptions.getGameUrl();

    windowMessageObserver.subscribe("greentube", handleWindowMessage);
  }

  function handleWindowMessage (data) {
    try {
      const messageData = JSON.parse(data);

      if (!messageData || !messageData.event) {
        return;
      }

      const { event } = messageData;

      switch (event.name) {
        case "b2b_freespins_package":
          freeSpinsFinished = false;

          break;
        case "freeSpinsFinished":
          handleFreeSpinsFinish(event);

          break;
      }
    } catch {}
  }

  function handleFreeSpinsFinish (event) {
    if (freeSpinsFinished === true) {
      return;
    }

    freeSpinsFinished = true;
    postMessage({ event });
  }

  function postMessage (message) {
    const iframe = document.getElementById(IFRAME_ID);
    const { contentWindow } = iframe;

    contentWindow.postMessage(JSON.stringify(message), "*");
  }

  return Object.freeze({
    setGameOptions,
    run,
  });
}

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./MenuGroup.css";

function MenuGroup ({ children, right, className }) {
  const cls = cx("l6r__menu-group", {
    [className]: className,
  });

  return (
    <div className={cls}>
      {children}
    </div>
  );
}

MenuGroup.propTypes = {
  children: PropTypes.node,
  right: PropTypes.any,
  className: PropTypes.string,
};

export default MenuGroup;

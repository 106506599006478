import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Iframe.css";

function Iframe ({
  className,
  id,
  src = "about:blank",
  onLoad = Function.prototype,
  scrolling = "no",
}) {
  const cls = cx("l6r__iframe", {
    [className]: className,
  });

  return (
    <iframe
      id={id}
      src={src}
      frameBorder="0"
      allow="autoplay;"
      scrolling={scrolling}
      className={cls}
      onLoad={onLoad}
      width="100%"
      height="100%"
    />
  );
}

Iframe.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  src: PropTypes.string,
  scrolling: PropTypes.string,
  onLoad: PropTypes.func,
};

export default Iframe;

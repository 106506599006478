import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Container.css";

function Container ({
  children,
  className,
  brand,
}) {
  const cls = cx("l6r__container", {
    [`l6r__theme--${brand}`]: brand,
    [className]: className,
  });

  return (
    <div className={cls}>
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  brand: PropTypes.string,
};

export default Container;

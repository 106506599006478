import BaseObserver from "./BaseObserver";
import { ORIENTATION_CHANGED_DIALOG_TYPE } from "../constants";

const ORIENTATION_CHANGE_EVENT = "orientationchange";
const WHITE_LIST = Object.freeze([
  "playtech",
  "quickspin",
  "relax",
  ORIENTATION_CHANGED_DIALOG_TYPE,
]);

function WindowOrientationObserver () {
  const base = new BaseObserver();
  base.setWhiteList(WHITE_LIST);

  function subscribe (id, handler) {
    if (!base.isAllowed(id)) {
      return;
    }

    base.addHandler(id, handler);

    if (!base.isActive()) {
      window.addEventListener(ORIENTATION_CHANGE_EVENT, handleOrientation);
      base.activate();
    }
  }

  function unsubscribe (id) {
    if (!base.isAllowed(id)) {
      return;
    }

    base.removeHandler(id);

    if (base.isEmpty()) {
      window.removeEventListener(ORIENTATION_CHANGE_EVENT, handleOrientation);
      base.disable();
    }
  }

  function handleOrientation () {
    base.execute();
  }

  return Object.freeze({
    subscribe,
    unsubscribe,
  });
}

const windowOrientationObserver = new WindowOrientationObserver();

export default windowOrientationObserver;

import { IFRAME_ID } from "../constants";
import windowMessageObserver from "../observers/WindowMessageObserver";

export default function Betgamestv () {
  let gameOptions = null;

  (() => {
    const iframe = document.getElementById(IFRAME_ID);

    iframe.setAttribute("scrolling", true);
  })();

  function setGameOptions (options) {
    gameOptions = options;
  }

  function run () {
    document.getElementById(IFRAME_ID).src = gameOptions.getGameUrl();

    windowMessageObserver.subscribe("betgamestv", handleWindowMessage);
  }

  function handleWindowMessage (data) {
    try {
      const messageData = JSON.parse(data);

      switch (messageData.type) {
        case "iframeTop": {
          const iframe = document.getElementById(IFRAME_ID);
          iframe.contentWindow.scrollTo(0, 0);
          break;
        }
      }
    } catch {}
  }

  return Object.freeze({
    setGameOptions,
    run,
  });
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Link from "../link/Link";
import SticksSpinner from "../loader/SticksSpinner";
import "./SpinnerButton.css";

function SpinnerButton ({
  className,
  color,
  icon,
  onClick = Function.prototype,
  text,
}) {
  const [ isSpinnerVisible, setIsSpinnerVisible ] = useState(false);

  const handleClick = async e => {
    e.preventDefault();

    if (isSpinnerVisible) {
      return;
    }

    setIsSpinnerVisible(true);

    try {
      await onClick();
      setIsSpinnerVisible(false);
    } catch (e) {
      setIsSpinnerVisible(false);
    }
  };

  const cls = cx("l6r__spinner-button", {
    "l6r__spinner-button--progress": isSpinnerVisible,
  });

  return (
    <div className={cls}>
      {isSpinnerVisible && (
        <div className="l6r__spinner-button-container">
          <SticksSpinner />
        </div>
      )}

      <Link
        className={className}
        color={color}
        text={text}
        icon={icon}
        onClick={handleClick}
      />
    </div>
  );
}

SpinnerButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["red", "green"]),
  icon: PropTypes.any,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default SpinnerButton;

import Validator from "./Validator";
import { TOKEN_KEY } from "../constants";

export default function UrlQueryValidator () {
  this.validate = function (query) {
    this.validateToken(query.get(TOKEN_KEY));

    return {
      isValid: this.isValid(),
      error: this.getError(),
    };
  };
}

UrlQueryValidator.prototype = new Validator();

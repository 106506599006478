import BaseObserver from "./BaseObserver";

const CLICK_EVENT = "click";
const WHITE_LIST = Object.freeze([
  "brand",
  "cashier",
  "help_phone",
  "menu-dialog",
  "limits-dialog",
  "low-balance-dialog",
]);

function ClickObserver () {
  const base = new BaseObserver();
  base.setWhiteList(WHITE_LIST);

  function subscribe (id, handler) {
    if (!base.isAllowed(id)) {
      return;
    }

    base.addHandler(id, handler);

    if (!base.isActive()) {
      document.addEventListener(CLICK_EVENT, handleClick);
      base.activate();
    }
  }

  function unsubscribe (id) {
    if (!base.isAllowed(id)) {
      return;
    }

    base.removeHandler(id);

    if (base.isEmpty()) {
      document.removeEventListener(CLICK_EVENT, handleClick);
      base.disable();
    }
  }

  function handleClick (e) {
    base.execute(e);
  }

  return Object.freeze({
    subscribe,
    unsubscribe,
  });
}

const clickObserver = new ClickObserver();

export default clickObserver;

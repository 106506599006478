import BasePlatform from "./BasePlatform";
import windowOrientationObserver from "../observers/WindowOrientationObserver";
import dialogObserver from "../observers/DialogObserver";
import { ORIENTATION_CHANGED_DIALOG_TYPE } from "../constants";

export default function Quickspin () {
  (() => {
    const openOrientationChangedDialog = () => {
      dialogObserver.fire(ORIENTATION_CHANGED_DIALOG_TYPE);
    };

    windowOrientationObserver.subscribe("quickspin", openOrientationChangedDialog);
  })();

  return new BasePlatform();
}

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { roundToNextTen as round } from "../../lib";
import "./CircularProgress.css";

function CircularProgress ({
  value,
  size = 30,
  strokeWidth = 2,
}) {
  const getRadius = () => {
    return (size / 2) - (strokeWidth / 2);
  };

  const getLength = () => {
    return getRadius() * 2 * Math.PI;
  };

  const getStrokeDashoffset = () => {
    const length = getLength();

    return length - (value / 100 * length);
  };

  const cls = cx("l6r__circ-progress", {
    [`l6r__circ-progress--color-${round(value)}`]: true,
  });

  return (
    <div
      className={cls}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height={size}
        width={size}
      >
        <circle
          className="l6r__circ-progress__ring"
          strokeWidth={strokeWidth}
          r={getRadius()}
          cx={size / 2}
          cy={size / 2}
        />

        <circle
          className="l6r__circ-progress__ring l6r__circ-progress__ring-progress"
          strokeWidth={strokeWidth}
          strokeDasharray={`${getLength()} ${getLength()}`}
          strokeDashoffset={getStrokeDashoffset()}
          r={getRadius()}
          cx={size / 2}
          cy={size / 2}
        />
      </svg>

      <div className="l6r__circ-progress__value">
        {`${value}%`}
      </div>
    </div>
  );
}

CircularProgress.propTypes = {
  value: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};

export default CircularProgress;

import * as Sentry from "@sentry/browser";
import appVersion from "./appVersion";

export default function initSentry (appName) {
  if (!process.env.SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    ignoreErrors: [
      /ethereum/gi,
      /blockchain/gi,
      "Non-Error promise rejection captured",
    ],
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.002,
    environment: "ATLANT_SENTRY_ENV",
    release: appVersion(),
  });
}

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./DialogContent.css";

function DialogContent ({ text, children, padded }) {
  const cls = cx("l6r__dialog-content", {
    "l6r__dialog-content--padded": padded,
  });

  return (
    <div className={cls}>
      {children}
      {text}
    </div>
  );
}

DialogContent.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  padded: PropTypes.any,
};

export default DialogContent;

import BasePlatform from "./BasePlatform";
import { IFRAME_ID } from "../constants";

export default function Spribe () {
  (() => {
    const iframe = document.getElementById(IFRAME_ID);

    iframe.setAttribute("scrolling", true);
  })();

  return new BasePlatform();
}

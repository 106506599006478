import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Link from "../link/Link";
import "./DialogFooter.css";

function DialogFooter ({
  buttons,
  bordered,
  confirmLabel,
  cancelLabel,
  onConfirmClick,
  onCancelClick,
}) {
  if (!onConfirmClick && !onCancelClick && !buttons) {
    return null;
  }

  const cls = cx("l6r__dialog-footer", {
    "l6r__dialog-footer--bordered": bordered,
  });

  return (
    <div className={cls}>
      {buttons && buttons.map(button => (
        <Link
          key={button.label}
          onClick={button.onClick}
          text={button.label}
        />
      ))}

      {onConfirmClick && (
        <Link
          onClick={onConfirmClick}
          text={confirmLabel}
          primary
        />
      )}

      {onCancelClick && (
        <Link
          onClick={onCancelClick}
          text={cancelLabel}
          secondary
        />
      )}
    </div>
  );
}

DialogFooter.propTypes = {
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  buttons: PropTypes.array,
  bordered: PropTypes.any,
};

export default DialogFooter;

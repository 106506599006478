import React from "react";
import PropTypes from "prop-types";
import Dot from "./Dot";
import Spinner from "./Spinner";
import "./Loader.css";

function Loader ({ loading, children, onClick }) {
  if (!loading) {
    return null;
  }

  return (
    <div className="l6r__loader" onClick={onClick}>
      {children}
    </div>
  );
}

Loader.Dot = Dot;
Loader.Spinner = Spinner;

Loader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Loader;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { clickObserver } from "../../lib";
import Icon from "../icons/Icon";
import "./DisclosureButton.css";

function DisclosureButton ({
  id,
  className,
  collapsWithAnyClick,
  icon: ButtonIcon,
  label,
  onCollaps = Function.prototype,
  onExpand = Function.prototype,
}) {
  const expandClass = "l6r__button--expanded";
  const button = useRef(null);
  const nextStatus = useRef(null);
  const [ status, setStatus ] = useState(null);

  useEffect(() => {
    switch (status) {
      case "opened": {
        if (collapsWithAnyClick) {
          clickObserver.subscribe(id, () => {
            setStatus("closed");
          });
        }
        break;
      }
      case "closed": {
        onCollaps();
        break;
      }
      case "toggle": {
        setStatus(nextStatus.current);
        nextStatus.current = null;
        break;
      }
    }

    return function clean () {
      clickObserver.unsubscribe(id);
    }
  }, [status, onExpand, onCollaps, collapsWithAnyClick, id]);

  const isExpanded = () => {
    return button.current.classList.contains(expandClass);
  };

  const handleClick = e => {
    e.preventDefault();

    const expanded = isExpanded();

    if (!expanded) {
      onExpand();
    }

    nextStatus.current = expanded ? "closed" : "opened";
    setStatus("toggle");
  };

  const cls = cx("l6r__button", {
    "l6r__button--disclosure": true,
    [expandClass]: status === "opened",
    [className]: className,
  });

  return (
    <div ref={button} className={cls} onClick={handleClick}>
      {ButtonIcon && (
        <div className="l6r__button-icon">
          <ButtonIcon />
        </div>
      )}

      {label && (
        <div className="l6r__button-label">
          {label}
        </div>
      )}

      <div className="l6r__button-chevron">
        <Icon.ArrowDown />
      </div>
    </div>
  );
}

DisclosureButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  collapsWithAnyClick: PropTypes.any,
  icon: PropTypes.any,
  label: PropTypes.string,
  onCollaps: PropTypes.func,
  onExpand: PropTypes.func,
};

export default DisclosureButton;

const ERROR_CODE = "gameIsUnavailable";

export default function LauncherError (errors) {
  this.errors = errors;

  this.getCode = () => {
    return ERROR_CODE;
  }
}

LauncherError.prototype = new Error();

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import MenuItem from "./MenuItem";
import MenuGroup from "./MenuGroup";
import "./Menu.css";

function Menu ({ children, className }) {
  const cls = cx("l6r__menu", {
    [className]: className,
  });

  return (
    <div className={cls}>
      {children}
    </div>
  );
}

Menu.Item = MenuItem;
Menu.Group = MenuGroup;

Menu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Menu;

import {
  BG_JURISDICTION,
  CW_JURISDICTION,
  DE_JURISDICTION,
  MT_JURISDICTION,
  NL_JURISDICTION,
  RO_JURISDICTION,
  SE_JURISDICTION,
  REAL_MODE,
  DEMO_MODE,
  GUEST_MODE,
  FRANK,
  SLOTV,
  DRIFT,
  MRBIT,
  APLAY,
  ASTRAJAM,
  APP_MOBILE,
  USER_PROGRESS_WIDGET,
  DAILY_CHALLENGES_WIDGET,
  TOURNAMENTS_WIDGET,
  RECENT_GAMES_WIDGET,
  PENDING_WITHDRAWALS_WIDGET,
  INSUFFICIENT_BALANCE_WIDGET,
  INDONESIA_CONTRY_CODE,
  CRICV_DOMAINS,
} from "../constants";

export default function GameOptions (gameOptions, hideUI) {
  const options = gameOptions;

  // project

  function getCountryCode () {
    return options.country_code;
  }

  function getJurisdiction () {
    return options.jurisdiction;
  }

  function getLocale () {
    return options.locale;
  }

  function getLobbyUrl () {
    return options.lobby_url;
  }

  function getProjectId () {
    return options.project_id;
  }

  function getBrand () {
    const brand = options.project_name;

    if (!brand) {
      return null;
    }

    if (brand.includes(FRANK)) {
      return FRANK;
    } else if (brand.includes(SLOTV)) {
      return SLOTV;
    } else if (brand.includes(APLAY)) {
      return APLAY;
    } else if (brand.includes(MRBIT)) {
      return MRBIT;
    } else if (brand.includes(DRIFT)) {
      return DRIFT;
    } else if (brand.includes(ASTRAJAM)) {
      return ASTRAJAM;
    } else {
      return null;
    }
  }

  function getDeviceType () {
    return options.device_type;
  }

  function getLicenseNumber () {
    return options.license_number;
  }

  function getHelpPhoneNumber () {
    return options.support_phone;
  }

  function getGameMode () {
    return options.mode;
  }

  function getAgeLimit () {
    return options.license_age;
  }

  function getVwoExperiments () {
    return options.vwo_experiments || {};
  }

  function getUserLimits () {
    const { player_limits: limits } = options;

    if (!limits) {
      return null;
    }

    const types = {
      deposits: 0,
      session_time: 1,
      balance: 2,
    };

    const periods = {
      day: 0,
      week: 1,
      month: 2,
    };

    const sortByType = (a, b) => (types[a.type] || 0) - (types[b.type] || 0);
    const sortByPeriod = (a, b) => (periods[a.period] || 0) - (periods[b.period] || 0);

    limits.sort(sortByType);

    return limits.reduce((acc, item) => {
      if (!acc.has(item.type)) {
        acc.set(item.type, {
          title: item.type,
          items: [],
        });
      }

      acc.get(item.type).items.push({ ...item, currency: getCurrency() });
      acc.get(item.type).items.sort(sortByPeriod);

      return acc;
    }, new Map());
  }

  function getCurrency () {
    return options.player_currency;
  }

  // platform

  function getPlatformName () {
    return options.platform_name;
  }

  function getParams () {
    return options.params;
  }

  // game

  function getGameUrl () {
    return options.url;
  }

  function getGameId () {
    return options.game_id;
  }

  function getGameName () {
    return options.game_name;
  }

  function getGameTitle () {
    return options.game_title;
  }

  function isInFavorites () {
    return options.game_favorite;
  }

  function getChannelId () {
    return options.channel_id;
  }

  function showUI () {
    return hideUI === false;
  }

  function showPlayForReal () {
    return [DEMO_MODE, GUEST_MODE].includes(getGameMode());
  }

  function showCashier () {
    return getGameMode() === REAL_MODE;
  }

  function showSwedenButtons () {
    return getJurisdiction() === SE_JURISDICTION;
  }

  function showPanicButton () {
    return (
      getJurisdiction() === DE_JURISDICTION &&
      getGameMode() !== GUEST_MODE
    );
  }

  function showHelpPhone () {
    return [
      MT_JURISDICTION,
      SE_JURISDICTION,
      DE_JURISDICTION,
      NL_JURISDICTION,
    ].includes(getJurisdiction());
  }

  function showAgeLimit () {
    return [
      MT_JURISDICTION,
      SE_JURISDICTION,
      DE_JURISDICTION,
      NL_JURISDICTION,
    ].includes(getJurisdiction());
  }

  function showLicense () {
    return getJurisdiction() === MT_JURISDICTION;
  }

  function showLimits () {
    return (
      getJurisdiction() === NL_JURISDICTION &&
      options.player_limits &&
      options.player_limits.length > 0
    );
  }

  function showOneClick () {
    return (showCashier() && options.one_click_available);
  }

  function showFavoritButton () {
    return getGameMode() !== GUEST_MODE;
  }

  function showUserWidgets (widget) {
    if (getGameMode() === GUEST_MODE) {
      return false;
    }

    switch (widget) {
      case USER_PROGRESS_WIDGET: {
        return (
          [FRANK, SLOTV, MRBIT].includes(getBrand()) &&
          [CW_JURISDICTION, MT_JURISDICTION, RO_JURISDICTION].includes(getJurisdiction())
        );
      }
      case TOURNAMENTS_WIDGET: {
        return (
          [FRANK, SLOTV, MRBIT, APLAY, DRIFT].includes(getBrand()) &&
          [CW_JURISDICTION, MT_JURISDICTION, RO_JURISDICTION].includes(getJurisdiction())
        )
      }
      case DAILY_CHALLENGES_WIDGET: {
        return (
          [MRBIT].includes(getBrand()) &&
          [CW_JURISDICTION, MT_JURISDICTION, RO_JURISDICTION].includes(getJurisdiction()) &&
          getDeviceType() !== APP_MOBILE
        );
      }
      case PENDING_WITHDRAWALS_WIDGET:
      case INSUFFICIENT_BALANCE_WIDGET: {
        return (
          [RO_JURISDICTION, BG_JURISDICTION].includes(getJurisdiction()) &&
          [FRANK, SLOTV, MRBIT].includes(getBrand()) &&
          getDeviceType() !== APP_MOBILE
        );
      }
      case RECENT_GAMES_WIDGET: {
        const vwos = getVwoExperiments();

        return (
          [FRANK].includes(getBrand()) &&
          getDeviceType() !== APP_MOBILE &&
          vwos["ABT-273"] === "Variation-1"
        )
      }
      default: return true;
    }
  }

  function getWidgetToken () {
    return options.project_token;
  }

  function getOrigin () {
    const originFromLobbyUrl = () => {
      if (!options.lobby_url) {
        return null;
      }

      try {
        const url = new URL(options.lobby_url);

        return url.origin;
      } catch {
        return false;
      }
    };

    return (
      options.project_origin ||
      originFromLobbyUrl() ||
      document.referrer
    );
  }

  function showSupportButton () {
    return (
      getBrand() !== ASTRAJAM &&
      !(getBrand() === SLOTV && getCountryCode() === INDONESIA_CONTRY_CODE)
    )
  }

  function getBrandIcon (Icon) {
    switch (getBrand()) {
      case FRANK: {
        if (getJurisdiction() === CW_JURISDICTION) {
          return Icon.FrankCW;
        }

        return Icon.Frank;
      }
      case SLOTV: {
        if (getJurisdiction() === CW_JURISDICTION) {
          if (CRICV_DOMAINS.some(domain => getLobbyUrl().includes(domain))) {
            return Icon.Cricv;
          }
          return Icon.SlotvCW;
        }

        return Icon.Slotv;
      }
      case DRIFT: return Icon.Drift;
      case MRBIT: {
        if (getJurisdiction() === CW_JURISDICTION) {
          return Icon.MrbitCW;
        }

        return Icon.Mrbit;
      }
      case APLAY: return Icon.Aplay;
      case ASTRAJAM: {
        if (getJurisdiction() === CW_JURISDICTION) {
          return Icon.AstrajamCW;
        }

        return Icon.Astrajam;
      }
      default: return null;
    }
  }

  function getTheme () {
    const brand = getBrand()

    switch (brand) {
      case ASTRAJAM: {
        if (getJurisdiction() === CW_JURISDICTION) {
          return `${brand}-cw`;
        }

        return brand;
      }
      default: return brand;
    }
  }

  return Object.freeze({
    getAgeLimit,
    getBrand,
    getBrandIcon,
    getChannelId,
    getCountryCode,
    getCurrency,
    getDeviceType,
    getGameId,
    getGameMode,
    getGameName,
    getGameTitle,
    getGameUrl,
    getHelpPhoneNumber,
    getJurisdiction,
    getLicenseNumber,
    getLobbyUrl,
    getLocale,
    getOrigin,
    getParams,
    getPlatformName,
    getProjectId,
    getTheme,
    getUserLimits,
    getVwoExperiments,
    getWidgetToken,
    isInFavorites,
    showAgeLimit,
    showCashier,
    showFavoritButton,
    showHelpPhone,
    showLicense,
    showLimits,
    showOneClick,
    showPanicButton,
    showPlayForReal,
    showSupportButton,
    showSwedenButtons,
    showUI,
    showUserWidgets,
  });
}

import screenfull from "screenfull";

export default ({
  isEnabled () {
    return screenfull && screenfull.isEnabled;
  },
  isFullscreen () {
    return this.isEnabled() && screenfull.isFullscreen;
  },
  toggle (element) {
    if (this.isEnabled()) {
      screenfull.toggle(element);
    }
  },
  exit () {
    if (this.isEnabled()) {
      screenfull.exit();
    }
  },
});

import React from "react";
import PropTypes from "prop-types";
import "./Popup.css";

function Popup ({ text }) {
  return (
    <div className="l6r__popup">
      {text}
    </div>
  );
}

Popup.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Popup;

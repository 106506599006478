import BasePlatform from "./BasePlatform";
import windowMessageObserver from "../observers/WindowMessageObserver";
import Command from "../commands/Command";

export default function Truelab () {
  (() => {
    const handleWindowMessage = data => {
      if (data.message === "leaveTheGame") {
        Command.openLobby();
      }
    };

    windowMessageObserver.subscribe("truelab", handleWindowMessage);
  })();

  return new BasePlatform();
}

import BaseObserver from "./BaseObserver";
import {
  ERROR_MESSAGE_DIALOG_TYPE,
  WARNING_MESSAGE_DIALOG_TYPE,
  MENU_DIALOG_TYPE,
  NOTIFICATION_MESSAGE_DIALOG_TYPE,
  ORIENTATION_CHANGED_DIALOG_TYPE,
  LOW_BALANCE_DIALOG_TYPE,
} from "../constants";

const WHITE_LIST = Object.freeze([
  "dialog",
  ERROR_MESSAGE_DIALOG_TYPE,
  MENU_DIALOG_TYPE,
  NOTIFICATION_MESSAGE_DIALOG_TYPE,
  ORIENTATION_CHANGED_DIALOG_TYPE,
  WARNING_MESSAGE_DIALOG_TYPE,
  LOW_BALANCE_DIALOG_TYPE,
]);

function DialogObserver () {
  const base = new BaseObserver();
  base.setWhiteList(WHITE_LIST);

  function fire (dialogType, params = {}) {
    base.execute(dialogType, params);
  }

  function subscribe (id, handler) {
    if (!base.isAllowed(id)) {
      return;
    }

    base.addHandler(id, handler);
  }

  function unsubscribe (id) {
    if (!base.isAllowed(id)) {
      return;
    }

    base.removeHandler(id);
  }

  return Object.freeze({
    subscribe,
    unsubscribe,
    fire,
  });
}

const dialogObserver = new DialogObserver();

export default dialogObserver;

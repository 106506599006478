import Command from "../commands/Command";
import { IFRAME_ID } from "../constants";

export default function Playngo () {
  let gameOptions = null;
  let targetOrigin = null;
  let origin = null;

  function setGameOptions (options) {
    gameOptions = options;
    targetOrigin = new URL(gameOptions.getGameUrl()).origin;
    origin = window.origin;
  }

  function run () {
    const GameCommunicator = {
      targetOrigin,
      source: null,
      init (element) {
        window.addEventListener("message", this.processGameMessage.bind(this));

        this.source = element.contentWindow;
        this.postMessage({
          messageType: "addEventListener",
          eventType: "logout",
        });

        this.postMessage({
          messageType: "addEventListener",
          eventType: "reloadGame",
        });

        this.postMessage({
          messageType: "addEventListener",
          eventType: "playForReal",
        });

        this.postMessage({
          messageType: "addEventListener",
          eventType: "backToLobby",
        });
      },
      postMessage (data) {
        this.source.postMessage(data, this.targetOrigin);
      },
      processGameMessage (e) {
        if (!e.data) {
          return;
        }

        switch (e.data.type) {
          case "logout":
          case "backToLobby":
            Command.openLobby();
            break;
          case "playForReal":
            Command.playForReal();
            break;
          case "reloadGame":
            run();
            break;
        }
      },
    };

    document.getElementById(IFRAME_ID).addEventListener("load", () => {
      GameCommunicator.init(document.getElementById(IFRAME_ID));
    });

    document.getElementById(IFRAME_ID).src = `${gameOptions.getGameUrl()}&origin=${origin}`;
  }

  return Object.freeze({
    setGameOptions,
    run,
  });
}

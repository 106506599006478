import { IFRAME_ID } from "../constants";

export default function BasePlatform () {
  let gameOptions = null;

  function setGameOptions (options) {
    gameOptions = options;
  }

  function run () {
    document.getElementById(IFRAME_ID).src = gameOptions.getGameUrl();
  }

  return Object.freeze({
    setGameOptions,
    run,
  });
}

import Command from "../commands/Command";
import { IFRAME_ID } from "../constants";

export default function Mascot () {
  let gameOptions = null;

  function setGameOptions (options) {
    gameOptions = options;

    replaceHistoryState();
  }

  function replaceHistoryState () {
    const { href } = window.location;
    window.history.replaceState({}, "", `${window.location.pathname}#goToLobby`);
    window.history.pushState({}, "", href);

    window.addEventListener("popstate", () => {
      if (window.location.hash === "#goToLobby") {
        Command.openLobby();
      }
    }, false);
  }

  function run () {
    document.getElementById(IFRAME_ID).src = gameOptions.getGameUrl();
  }

  return Object.freeze({
    setGameOptions,
    run,
  });
}

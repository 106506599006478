import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Dot.css";

function Dot ({ animation }) {
  const cls = cx("l6r__dot", {
    [`l6r__dot--${animation}`]: animation,
  });

  return (
    <div className={cls} />
  );
}

Dot.propTypes = {
  animation: PropTypes.string,
}

export default Dot;

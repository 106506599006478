const ERROR_CODE = "gameIsUnavailable";

export default function AtlantAPIError (error) {
  this.error = error;

  this.getCode = () => {
    if (this.error.code) {
      return this.error.code;
    }

    return ERROR_CODE;
  }
}

AtlantAPIError.prototype = new Error();

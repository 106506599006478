import LauncherError from "../errors/LauncherError";

export default function Validator () {
  this.errors = [];
}

Validator.prototype.validateProjectName = function (value) {
  if (!value || value.length === 0) {
    this.addError("Project name is empty");
  }
}

Validator.prototype.validateToken = function (value) {
  if (!value || value.length === 0) {
    this.addError("Token is empty");
  }
}

Validator.prototype.addError = function (error) {
  this.errors.push(error);
}

Validator.prototype.isValid = function () {
  return this.errors.length === 0;
}

Validator.prototype.getError = function () {
  if (this.errors.length > 0) {
    return new LauncherError(this.errors);
  }

  return null;
}

import BasePlatform from "./BasePlatform";
import windowMessageObserver from "../observers/WindowMessageObserver";
import Command from "../commands/Command";
import { IFRAME_ID } from "../constants";

export default function EgtDigital () {
  (() => {
    const handleWindowMessage = data => {
      const { command } = data;

      if (command === "com.egt-bg.exit") {
        Command.openLobby();
      }
    };

    windowMessageObserver.subscribe("egtDigital", handleWindowMessage);

    const iframe = document.getElementById(IFRAME_ID);
    iframe.setAttribute("allow", "autoplay *; screen-wake-lock *; fullscreen *;");
  })();

  return new BasePlatform();
}

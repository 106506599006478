import appVersion from "./appVersion";

export default function consoleLog (message = "") {
  const labelStyle = "color:white; background-color: tomato;";
  const messageStyle = "color: black; background-color: orange;";

  // eslint-disable-next-line no-console
  console.log(
    `%c Launcher version is ${appVersion()} %c ${message} `,
    labelStyle,
    messageStyle,
  );
}
